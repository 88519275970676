@import 'styles/colors.scss';

.flyoutMenu {
    position: fixed;
    top: 0;
    right: 0;
    width: 384px;
    height: 100%;
    background-color: $tyrianPurple;
    z-index: 50;
    transition: transform .5s ease-in-out;
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
    }

    @media screen and (max-width: 576px) {
        width: 100%;
    }
}

.overlayBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    z-index: 50;
    transition: transform .5s ease-in-out;
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
    }
}

.hide {
    transform: translate3d(100vw, 0, 0);
}

.show {
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
}

.close{
    float: right;
    margin: 50px 80px;

    @media screen and (max-width: 576px) {
        margin: 50px 20vw;
    }
}

.close svg{
    width: 20px;
}

.links{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 0;
}

.links > *{
    margin-top: 40px;
    color: $white !important;
}

.navigationHeader{
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.media{
    margin: 50px 80px;

    @media screen and (max-width: 576px) {
        margin: 50px 20vw;
    }
}