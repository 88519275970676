@import 'styles/colors.scss';

.paragraph{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
}

.paragraphHeader{
    composes: paragraph;
    font-weight: bold;
    font-size: 22px;
}

.littleHeader{
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 500;

    margin-top: 0;
}

.list{
    composes: paragraph;

    list-style-type: none;
    padding: 0;
}

.indentedList{
    composes: list;
    padding-left: 40px;
}

.list a{
    text-decoration: none;
}

.highlightedList{
    composes: list;
    
    font-weight: 500;
    line-height: 45px;
}