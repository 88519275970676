$itemsPerRowLarge: 3;
$itemsPerRowMedium: 2;
$itemsPerRowSmall: 1;

@mixin set-children-width($itemCount) {
	& > * {
		width: calc(100% / #{$itemCount});
	}
}

.container{
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin: 40px;

	@include set-children-width($itemsPerRowLarge);
	
	@media screen and (max-width: 1450px) {
		@include set-children-width($itemsPerRowMedium);
	}
	
	@media screen and (max-width: 768px) {
		@include set-children-width($itemsPerRowSmall);
		margin: 20px;
	}
}

.header{
	font-family: 'Playfair Display', serif;
	font-size: 70px;
	font-weight: 500;
    margin: 0;
}

.pageSelection{
	margin: auto;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 50px;
}

.icon{
	vertical-align: middle;
}