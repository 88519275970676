@import 'styles/colors.scss';

.container{
    padding: 20px 8vw;
    justify-content: space-between;
    align-items: center;
    display: none;

    @media screen and (max-width: 1024px) {
        display: flex;
    }
}

.logo{
    width: 200px;
}

.open svg{
    width: 20px;
}

.buttons > * {
    margin: 0 3vw;
}

.hideOnMobile{
    display: inline-block;

    @media screen and (max-width: 576px) {
        display: none;
    }
}