@import 'styles/colors.scss';

.template{
    width: 100%;
    max-width: 640px;
    box-sizing: border-box;
    padding: 25px 3vw;
    margin-top: 40px;
}

.icon{
    width: 56px;
    height: auto;

    display: block;
    margin: auto;
}

.loadingIcon{
    composes: icon;
    -webkit-animation: rotating 4s linear infinite;
    -moz-animation: rotating 4s linear infinite;
    -ms-animation: rotating 4s linear infinite;
    -o-animation: rotating 4s linear infinite;
    animation: rotating 4s linear infinite;
}

.text{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
}

.loading{
    composes: template;
}

.success{
    composes: template;

    background-color: $infoBg;
}

.failure{
    composes: template;

    background-color: $failureBg;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}