.mainContainer{
	margin: 100px 10vw;
	display: flex;
	flex-wrap: wrap;

	@media screen and (max-width: 1560px) {
		margin: 100px 5vw;
	}

	@media screen and (max-width: 1024px) {
		margin: 50px 5vw;
	}

	@media screen and (max-width: 576px) {
		margin: 25px 5vw;
	}
}

.left{
	width: 400px;
	margin-right: 100px;

	@media screen and (max-width: 1560px) {
		width: 300px;
	}

	@media screen and (max-width: 1024px) {
		width: 200px;
		margin-right: 50px;
	}

    @media screen and (max-width: 576px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}
}

.right{
	width: calc(100% - 400px - 100px);

	@media screen and (max-width: 1560px) {
		width: calc(100% - 300px - 100px);
	}

	@media screen and (max-width: 1024px) {
		width: calc(100% - 200px - 50px);
	}

	@media screen and (max-width: 576px) {
		width: 100%;
	}
}