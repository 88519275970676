@import 'styles/colors.scss';

.container{
    background-color: $lightCream;
    
    & > div > div{
        margin-top: 0;
        margin-bottom: 0;
        padding: 80px 0;
    }
}

.image{
    width: 100%;
    max-width: 300px;
    margin: auto;
    display: block;
}

.textContainer{
    font-size:20px;
    height:100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;

    @media screen and(max-width: 1024px) {
        max-width: 300px;
    }
}

.littleHeader {
	font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 42px;
    margin: 0 0 15px;
    
    @media screen and(max-width: 1024px) {
        font-size: 28px;
    }
}

.text{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
}

.email{
    color: $tyrianPurple;
    font-weight: 500;
    text-decoration: none;
}