@import 'styles/colors.scss';

.container > div{ // Overwriting header template default values
	background-color: $infoBg;

	@media screen and (max-width: 1024px) {
		padding: 100px 25vw;
	}
	
	@media screen and (max-width: 576px) {
		padding: 100px 5vw;
    }
}

.header{
	font-family: 'Playfair Display', serif;
	font-size: 70px;
	margin-bottom: 50px;

	@media screen and (max-width: 1024px) {
		font-weight: 500;
		font-size: 54px;
	}
	
	@media screen and (max-width: 576px) {
		text-align: center;
		font-size: 48px;
    }
}

.littleHeader{
	composes: header;
	font-size: 40px;
	font-weight: 500;
	margin-bottom: 25px;
	text-align: left;

	@media screen and (max-width: 1024px) {    
		font-size: 28px;
	}
}

.paragraph{
	font-family: futura-pt, sans-serif;
	font-size: 20px;
	line-height: 35px;
	margin-bottom: 50px;

	@media screen and (max-width: 1024px) {    
		font-size: 18px;
    }
}

.button{
	background-color: $infoButton !important;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			border: 2px solid $infoButton;
			background-color: transparent !important;
			color: $tyrianPurple;
		}
	}

	@media screen and (max-width: 1024px) {    
		width: 100% !important;
    }
}

.link{
	font-family: 'Playfair Display', serif;
	font-size: 20px;
	font-weight: 500;
	word-break: break-all;
	margin-bottom: 30px;
	white-space: nowrap;

	@media screen and (max-width: 1024px) {    
		font-size: 18px;
	}
	
	@media screen and (max-width: 576px) {    
		white-space: normal;
    }
}

.link > a{
	display: inline;
	text-decoration: none;
	vertical-align: middle;

	@media screen and (max-width: 576px) {
		display: block;
		text-align: center;
    }
}

.link > svg{
	width: auto;
	height: 25px;
	margin-right: 10px;
	vertical-align: middle;
	opacity: 0.25;

	@media screen and (max-width: 576px) {
		margin: auto;
		margin-bottom: 10px;
		display: block;
    }
}