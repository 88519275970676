@import 'styles/colors.scss';

.header{
    font-family: 'Playfair Display', serif;
    font-size: 70px;
    font-weight: 500;
}

.littleHeader{
    composes: header;
    font-size: 40px;
    font-weight: normal;
    margin: 35px 0;
}

.paragraph{
    font-family: futura-pt, sans-serif;
    font-size: 20px;

    margin: 20px 0;
}

.paragraphMedium{
    composes: paragraph;
    font-weight: 500;
    display: inline;
}

.tableContainer{
    overflow-x: auto;
}

.table{
    composes: paragraph;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    max-width: 690px;
}

.table thead{
    background-color: $cream;
}

.table tbody{
    background-color: $lightCream;
}

.table th{
    border: 1px solid $lightCream;
    padding: 20px;
    font-weight: 500;
}

.table td{
    border: 1px solid $cream;
    padding: 15px 20px;
    font-size: 18px;
    word-break: break-word;
}