@import 'styles/colors.scss';

.pagination {
    display: inline-block;
}
  
.pagination button {
    background-color: $white;
    color: $tyrianPurple;
    float: left;
    padding: 8px 8px;
    border: none;
	font-family: 'Playfair Display', serif;
    font-size: 24px;
    opacity: 0.3;
    outline: none;
    cursor: pointer;
}

.pagination button.active {
    opacity: 1;
}
  
.pagination button:hover:not(.active) {opacity: 1;}