@import 'styles/colors.scss';

.container{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
    color: $infoText !important;

    background-color: $infoBg;
    padding: 20px 30px;
    margin: 40px 0;

    display: flex;

    @media screen and (max-width: 576px) {
		display: block;
	}
}

.container div, .container p, .container span, .container a{
    color: $infoText !important;
}

.icon{
    min-width: 40px;
    max-width: 40px;
    height: auto;

    margin-right: 25px;

    @media screen and (max-width: 576px) {
        min-width: 50px;
        max-width: 50px;
        display: block;
        margin: auto;
	}
}