.header{
	font-family: 'Playfair Display', serif;
    font-size: 60px;
    font-weight: 500;
    margin: 0;
    position: relative;
    
    @media screen and (max-width: 384px) {
		font-size: 50px;
	}
}

.logo{
    position: absolute;
    bottom:-60px; right:50px;

    @media screen and (max-width: 576px) {
		bottom:-60px; right:10px;
	}
}

.logo svg{
    height: 120px;
    width: auto;
}