@import 'styles/colors.scss';

.mainContainer{
    background-color: $lightCream;

    padding: 100px 2vw 100px 10vw;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1560px) {
        padding: 100px 2vw 100px 5vw;
    }
}

.left{
    font-family: 'Playfair Display', serif;
    font-size: 70px;

	width: 30vw;
    padding-right: 20px;
    
    @media screen and (min-width: 1025px) {
        word-spacing: 100vw;
    }

    @media screen and (max-width: 1024px) {
		width: 100%;
		padding-right: 0;
        margin-bottom: 30px;
        
        text-align: center;
    }
    
    @media screen and (max-width: 576px) {
        font-size: 60px;
	}
}

.right{
	width: 63%;

	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.littleHeader{
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: 500;

    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        text-align: center;
    }
}

.paragraph{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 30px;
    padding-right: 8vw;

    @media screen and (max-width: 1560px) {
        padding-right: 3vw;
    }
}

.services{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media screen and (max-width: 1024px) {
        justify-content: center;
    }
}

.serviceBox{
    width: auto;
    width: 285px;
    height: 300px;
    position: relative;
    text-decoration: none;
}

@media (hover: hover) and (pointer: fine) {
    .serviceBox:hover{
        background-color: $cream;

        .serviceImage svg{
            opacity: 1;
        }
    }
}

.serviceTitle{
    font-family: 'Playfair Display', serif;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: 180px;
    padding: 0 5px;
    width: 100%;
    box-sizing: border-box;
}

.serviceImage svg{
    height: 80px;
    width: auto;

    display: block;
    margin: auto;
    margin-top: 80px;
    opacity: 0.5;

    @media screen and (max-width: 1024px) {
        opacity: 1;
    }
}