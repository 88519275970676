@import 'styles/colors.scss';

.backButton {
	font-family: 'Playfair Display', serif;
	font-size: 24px;
	font-weight: 500;

	@media screen and (max-width: 1024px) {
		text-align: center;
		display: block;
	}
}

.link{
	text-decoration: none;
}