@import "styles/colors.scss";

.field {
  font-family: futura-pt, sans-serif;
  font-size: 18px;

  display: block;
  background-color: $cream;
  border: 0;
  margin-top: 40px;
  padding: 30px 40px;
  box-sizing: border-box;
  outline: none;
  resize: none;

  width: 100%;
  max-width: 640px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: none;
  }

  @media screen and (max-width: 576px) {
    padding: 30px 20px;
  }
}

.invalidField {
  composes: field;
  border: 1px solid $invalidRed;
}

.textAreaField {
  composes: field;
  resize: none;
  height: 200px;
}

.invalidTextAreaField {
  composes: textAreaField;
  border: 1px solid $invalidRed;
}

.label {
  font-family: futura-pt, sans-serif;
  font-size: 18px;
  margin: 10px 0 10px -40px;
}

.checkmarkGroup {
  margin-top: 26px;
  margin-bottom: 40px;
  padding-left: 40px;

  @media screen and (max-width: 576px) {
    padding-left: 20px;
  }
}

.smallText {
  font-family: futura-pt, sans-serif;
  width: 100%;
  max-width: 640px;
  font-size: 16px;
  margin: 20px 0;

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: none;
  }
}

.invalidText {
  composes: smallText;
  color: $invalidRed;
  margin-top: 10px;
  font-weight: 500;
}

.submitButton {
  width: 100% !important;
  max-width: 640px !important;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
    max-width: none !important;
  }
}

.datePicker {
  composes: field;
  margin: 0;

  div {
    border: none;
  }
}

.checkmarkLabel {
  font-family: futura-pt, sans-serif;
  font-size: 18px;

  display: block !important;
  position: relative;
  padding-left: 30px;
  margin-bottom: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmarkLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $white;
  border: 1px solid $tyrianPurpleInactive;
  border-radius: 25%;
  margin-top: 2px;
}

/* On mouse-over, add a grey background color */
.checkmarkLabel:hover input ~ .checkmark {
  border-color: $tyrianPurple;
}

.checkmarkLabel input:checked ~ .checkmark {
  border-color: $tyrianPurple;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmarkLabel input:checked ~ .checkmark:after {
  display: block;
  border-color: $tyrianPurple;
}

/* Style the checkmark/indicator */
.checkmarkLabel .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid $tyrianPurple;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $tyrianPurple;
  opacity: 1; /* Firefox */
}

.field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $tyrianPurple;
}

.field::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $tyrianPurple;
}
