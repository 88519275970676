$white: #ffffff;
$tyrianPurple: #4a193d;
$tyrianPurpleInactive: #b398ab;
$tyrianPurpleHalfOpacity: #9f8595;
$cream: #f4f1ed;
$lightCream: #fdfcfb;
$thistle: #aa92a0;
$infoBg: #edf3f4;
$infoText: #364648;
$infoButton: #3c5559;
$successBg: #a0d6ac;
$failureBg: #dbd1d8;
$info: #364648;
$whiteCream: #fcfcfb;
$darkBlue: #104a8e;
$invalidRed: #dc3545;
