@import 'styles/colors.scss';
/* autoprefixer grid: on */

.container {
	display: grid;
	margin: auto auto;
	background-color: $cream;

	@media screen and (min-width: 930px) {
		grid-template-columns: auto;
	}

	@media screen and (min-width: 1450px) {
		grid-template-columns: 50% 50%;
	}
}

.container svg {
	margin: 50px auto 0 auto;
	align-self: center;
	justify-self: center;
	max-width: 340px;
	max-height: 340px;
	width: 340px;
	height: 340px;
	@media screen and (min-width: 450px) {
		max-width: 420px;
		max-height: 420px;
		width: 420px;
		height: 420px;
	}
	@media screen and (min-width: 930px) {
		max-width: 500px;
		max-height: 500px;
		width: 500px;
		height: 500px;
	}
	@media screen and (min-width: 1450px) {
		margin: 50px;
		max-width: 500px;
		max-height: 500px;
	}
}

.bankImage {
	display: grid;
	width: 100%;
	grid-column-start: 1;
	grid-column-end: 2;
	align-self: center;
	justify-self: center;

	@media screen and (max-width: 576px) {
        overflow: hidden;
    }
}

.text {
	align-self: center;
	margin: 50px 15% 50px 15%;

	@media screen and (min-width: 930px) {
		margin: 50px 15% 50px 0;

		grid-column-start: 2;
		grid-column-end: 3;
	}

	@media screen and (min-width: 1450px) {
		margin: 5em 35% 5em 0;
	}
}

.header {
	font-family: 'Playfair Display', serif;
	font-size: 55px;
	font-weight: 500;
	text-align: center;
	margin: 0;

	@media screen and (min-width: 930px) {
		text-align: left;
	}
}

.paragraph {
	font-family: futura-pt, sans-serif;
	font-size: 22px;
	line-height: 40px;

	margin: 50px 0;
}

.littleHeader {
	font-family: 'Playfair Display', serif;
	font-size: 55px;
	margin: 30px 30px 0 30px;
	text-align: center;

	@media screen and (min-width: 930px) {
		text-align: left;
		margin-left: 100px;
	}
}

.button{
	@media screen and (max-width: 1024px) {
		width: 100% !important;
	}
}