@import 'styles/colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,500,700');
@import url('https://use.typekit.net/qil5hjr.css');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html{
	scroll-behavior: smooth;
}

h1{
	font-weight: normal;
	color: $tyrianPurple;
}

h2{
	font-weight: normal;
	color: $tyrianPurple;
}

h3{
	font-weight: normal;
	color: $tyrianPurple;
}

div{
	color: $tyrianPurple;
}

p{
	color: $tyrianPurple;
}

a, a:visited, a:active{
	color: $tyrianPurple
}

// https://github.com/kenwheeler/slick/issues/179
.slick-track{
    display: flex;

    .slick-slide{
        display: flex;
        height: auto;
    }
}

.slick-dots{
	bottom: -30px;

	li{
		margin: 0 8px;
	}
}

.simpleButton{
	border: none;
	color: $white;
	cursor: pointer;
	background-color: $tyrianPurple;
	font-family: 'Playfair Display', serif;
	font-size: 24px;
	height: 3em;
	width: 15em;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background-color: $tyrianPurpleHalfOpacity;
		}
	}
}