.arrow{
    width: 7px;
    margin: 0 15px;
}

.link{
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    text-decoration: none;
    display: inline;
}

.routeBlock{
    display: inline;
    text-decoration: none;
}