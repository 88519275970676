@import 'styles/colors.scss';

.paragraph {
	font-family: futura-pt, sans-serif;
	font-size: 20px;
}

.header {
	font-family: 'Playfair Display', serif;
	font-size: 65px;
	margin-left: 100px;
	font-weight: 500;

	@media screen and (max-width: 1560px) {
		font-size: 55px;
	}

	@media screen and (max-width: 1024px) {
		font-size: 55px;
		margin: 0 10px;
		text-align: center;
	}

	@media screen and (max-width: 576px) {
		font-size: 45px;
	}
}

.headline{
	@extend .header;
	margin: 0;
}

.headerImage img {
	object-position: 50% 50%;
	object-fit: cover;
	width: 100%;
	height: 400px;
}

.author {
	@extend .paragraph;
	text-decoration: none;
	font-size: 22px;
	font-weight: 500;
	margin: 0;
	display: block;
	width: fit-content;

	@media screen and (max-width: 1024px) {
		margin: 0 auto;
	}
}

.date{
	@extend .author;
	font-size: 20px;
	opacity: 0.5;
}

.authorSection{
	margin: 30px 0;

	& > *{
		margin-bottom: 10px;
	}

	.image{
		width: 160px;
		height: 160px;
		clip-path: circle(80px at center);
		display: block;
		margin: 20px 0;
	
		@media screen and (max-width: 1024px) {
			margin: 20px auto;
		}
	}
}